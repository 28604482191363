/* this file is transformed by vux-loader */
/* eslint-disable */
var instStatusColor = {
  'RUNNING': {
    color: '#fff',
    background: '#25c4b5',
    name: '运行中'
  },
  'SUCCESS_END': {
    color: '#fff',
    background: '#f76593',
    name: '结束'
  },
  'CMU': {
    color: '#fff',
    background: '#8bc34a',
    name: '会签'
  },
  'CMUSEND': {
    color: '#fff',
    background: '#03a9f4',
    name: '会签发出'
  },
  'APP': {
    color: '#fff',
    background: '#FFC107',
    name: '待审批'
  },
  'CANCEL_END': {
    color: '#fff',
    background: '#de1d0a',
    name: '异常结束'
  },
  'AGENT': {
    color: '#fff',
    background: '#3dc976',
    name: '代办'
  },
  'DISCARD_END': {
    color: '#fff',
    background: '#de0e34',
    name: '作废结束'
  },
  'ABORT_END': {
    color: '#fff',
    background: '#dc829f',
    name: '异常中止'
  },
  'DRAFTED': {
    color: '#fff',
    background: '#dc829f',
    name: '草稿'
  }
};
export default {
  instStatusColor
};