/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { loadRefreshList } from '@/assets/app.js';
import listMore from '@/components/common/listMore';
import { RxUtil } from "@/assets/util.js";
import { convertBoJson } from '@/assets/app.js';
import { ValidUtil } from '@/assets/app.js';
import { validNodesUser, getNodeUserJson } from '@/assets/flow/bpmtask.js';
import instStatusColor from '@/assets/instStatusColor.js';
Vue.component(listMore.name, listMore);
export default {
  name: 'to-do-list',
  data() {
    return {
      list: [],
      pageSize: 20,
      pageIndex: 0,
      total: 0,
      showTips: false,
      index: 0,
      searchVal: ""
    };
  },
  components: {},
  computed: {
    toDoSearch() {
      return this.$store.state.searchModule.toDoSearchVal;
    }
  },
  watch: {
    toDoSearch: function (val, oldVal) {
      if (val != oldVal) {
        this.searchVal = val;
        this.loadList(true, false);
      }
    }
  },
  created() {
    this.loadList(true, false);
  },
  methods: {
    toTask(item) {
      this.$router.push({
        name: 'task',
        params: {
          taskId: item.id,
          from: 'tasks'
        }
      });
    },
    refreshList() {
      this.loadList(true, false);
    },
    loadList(initPage, merge) {
      var url = _baseUrl + "/bpm/core/bpmTask/myAgents.do";
      if (initPage) {
        this.pageIndex = 0;
      } else {
        this.pageIndex++;
      }
      var params = "agent=toMe&pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize;
      if (this.searchVal) {
        params += "&Q_description_S_LK=" + this.searchVal;
      }
      loadRefreshList(url, params, this, data => {
        this.total = data.total;
      }, merge);
    },
    getStatusHtml(item) {
      var status = "";
      if (item.rcTaskId) {
        status = "CMU";
      } else if (item.genCmTask == 'YES') {
        status = "CMUSEND";
      } else if (item.status == 'RUNNING') {
        status = 'APP';
      }
      return this.getStyByStatus(status);
    },
    getStyByStatus(status) {
      var str = "";
      var sts = instStatusColor.instStatusColor[status];
      if (sts) {
        var color = sts.color;
        var background = sts.background;
        var name = sts.name;
        str = "<span class='inst-list-status' style='color:" + color + ";background:" + background + "'>" + name + "</span>";
      } else {
        str = "<span class='inst-list-status'>" + status + "</span>";
      }
      return str;
    },
    back(task) {
      if (task.rcTaskId || task.genCmTask == 'YES') {
        this.errorToast('会签任务，不允许操作', 1500);
        return;
      }
      this.$dialog.confirm({
        mes: '是否驳回至发起人？',
        opts: [{
          txt: '取消'
        }, {
          txt: '确定',
          color: '#25c4b5',
          callback: () => {
            this.backAction(task);
          }
        }]
      });
    },
    backAction(task) {
      var url = _baseUrl + "/mobile/bpm/canReject.do?taskId=" + task.id;
      var params = {};
      this.$ajax.post(url, params).then(response => {
        var rtn = response.data;
        if (rtn.result == "taskEnd") {
          this.errorToast('任务已经结束');
        } else if (!rtn.canReject) {
          this.errorToast('该节点不能驳回');
        } else {
          this.reject(task);
        }
      });
    },
    reject(task) {
      var approveCmd = {
        jumpType: "BACK_TO_STARTOR",
        taskId: task.id
      };
      var url = _baseUrl + "/bpm/core/bpmTask/doNext.do";
      var params = RxUtil.serialJsonToForm(approveCmd);
      this.$dialog.loading.open("正在驳回...");
      this.$ajax.post(url, params).then(response => {
        this.$dialog.loading.close();
        var data = response.data;
        if (data.success) {
          this.successToast("驳回成功", 2000);
          this.loadList(true, false);
        } else {
          this.errorToast(data.message, 2000);
        }
      }).catch(error => {
        this.$dialog.loading.close();
        console.log(error);
      });
    },
    agree(task) {
      if (task.rcTaskId || task.genCmTask == 'YES') {
        this.errorToast('会签任务，不允许操作', 1500);
        return;
      }
      this.$dialog.confirm({
        mes: '是否自动审批任务？',
        opts: [{
          txt: '取消',
          color: false
        }, {
          txt: '确定',
          color: '#25c4b5',
          callback: () => {
            this.agreeAction(task);
          }
        }]
      });
    },
    agreeAction(task) {
      var formMobileIds = RxUtil.getCache("formMobileIds");
      var url = _baseUrl + "/mobile/bpm/getTaskInfo.do?taskId=" + task.id;
      var params = {};
      if (formMobileIds) {
        params = "versionJson=" + formMobileIds;
      }
      this.$ajax.post(url, params).then(response => {
        var data = response.data;
        if (data.result == "taskEnd") {
          this.errorToast('任务已经结束', 1500);
          return;
        }
        var formModels = data.formModels;
        if (!formModels) {
          this.errorToast('没有配置表单', 1500);
          return;
        }
        for (let i = 0, j = formModels.length; i < j; i++) {
          var formModel = formModels[i];
          let permission = formModel.permission ? eval('(' + formModel.permission + ')') : {};
          var rtn = ValidUtil.valid(true, formModel.boDefId, formModel.jsonData, permission);
          if (!rtn.valid) {
            this.errorToast(rtn.msg, 1500);
            return;
          }
        }
        var jsonData = convertBoJson(formModels);
        let taskId = task.id;
        var postData = {
          taskId: taskId,
          jsonData: jsonData
        };
        var cmd = {
          postData: JSON.stringify(postData),
          taskId: taskId,
          jumpType: 'AGREE'
        };
        //获取节点人员
        this.approveUserList(cmd, formModels, taskId);
      });
    },
    approveUserList(cmd, formModels, taskId) {
      var url = _baseUrl + "/bpm/core/bpmTask/approveUserList.do";
      var params = RxUtil.serialJsonToForm(cmd);
      var self = this;
      this.$ajax.post(url, params).then(response => {
        if (response.status == 200) {
          var destNodeUserList = response.data.list;
          var approveCmd = {};
          approveCmd.taskId = taskId;
          approveCmd.jumpType = 'AGREE';
          var jsonData = convertBoJson(formModels);
          approveCmd.jsonData = JSON.stringify(jsonData);

          //判断节点
          if (!validNodesUser(destNodeUserList, "")) {
            self.errorToast("下一级节点没有选择审批人,进入填写", 1000);
            return;
          }
          var destNodeUsers = getNodeUserJson(destNodeUserList, "");
          approveCmd.destNodeUsers = destNodeUsers;
          self.doNext(approveCmd);
        } else {
          self.errorToast("没有获取到节点信息,进入填写", 1000);
          return;
        }
      }).catch(error => {});
    },
    doNext(approveCmd) {
      var url = _baseUrl + "/bpm/core/bpmTask/doNext.do";
      var params = RxUtil.serialJsonToForm(approveCmd);
      this.$dialog.loading.open("正在审批...");
      this.$ajax.post(url, params).then(response => {
        this.$dialog.loading.close();
        var data = response.data;
        if (data.success) {
          this.successToast("操作成功！", 1500);
          this.loadList(true, false);
        } else {
          this.errorToast(data.message, 2000);
        }
      }).catch(error => {
        this.$dialog.loading.close();
        console.log(error);
      });
    }
  }
};