/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
/**
 * 将formmodels转换成 提交的JSON结构。
 */
export function getNodeUserJson(nodeList) {
  var nodeUsers = [];
  for (var i = 0; i < nodeList.length; i++) {
    var node = nodeList[i];
    var nodeUser = node.taskNodeUser;
    if (nodeUser) {
      var userJsons = nodeUser.userIdsAndText;
      var userIds = getUserIds(userJsons);
      if (!userIds) continue;
      var tmpUser = {
        nodeId: nodeUser.nodeId,
        userIds: userIds
      };
      nodeUsers.push(tmpUser);
    } else {
      for (var nodeId in node.fllowNodeUserMap) {
        var user = node.fllowNodeUserMap[nodeId];
        var userJsons = user.userJsons;
        var userIds = getUserIds(userJsons);
        if (!userIds) continue;
        var tmpUser = {
          nodeId: user.nodeId,
          userIds: userIds
        };
        nodeUsers.push(tmpUser);
      }
    }
  }
  return JSON.stringify(nodeUsers);
}

/**
 * 校验节点用户
 */
export function validNodesUser(destNodeUserList, destNodeId) {
  for (var i = 0; i < destNodeUserList.length; i++) {
    var destNodeUser = destNodeUserList[i];
    var taskNodeUser = destNodeUser.taskNodeUser;
    var nodeId = destNodeUser.nodeId;
    if (taskNodeUser) {
      var userIds = taskNodeUser.userIds;
      if (destNodeId && !userIds) {
        return false;
      } else if (destNodeId == nodeId && !userIds) {
        return false;
      }
    } else {
      for (var key in destNodeUser.fllowNodeUserMap) {
        var destMap = destNodeUser.fllowNodeUserMap[key];
        var userIds = destMap.userIds;
        if (destNodeId && !userIds) {
          return false;
        } else if (destNodeId == nodeId && !userIds) {
          return false;
        }
      }
    }
  }
  return true;
}

//[{id:"",text:""},{id:"",text:""}]
function getUserIds(userJsons) {
  var jsonAry = eval(userJsons);
  var idAry = [];
  if (!jsonAry || jsonAry.length == 0) return "";
  for (var i = 0; i < jsonAry.length; i++) {
    var o = jsonAry[i];
    idAry.push(o.id);
  }
  return idAry.join(",");
}